window.require = require
window.jQuery = window.$ = require('jquery')


var Tripartite = require('tripartite')
/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

var lightboxSetup = require('photo-lightbox')

require('page-editor').configure()

$('nav [href="' + window.location.pathname + '"]').each(function() {
	$(this).closest('li').addClass('current')
})

lightboxSetup('.slides .slide')

$('.tribar').click(function(evt) {
	evt.preventDefault()
	$('body').toggleClass('tribar-menu-open')
})