var _ = require('underscore')

$('.grid-condensor').click(function(evt) {
	evt.preventDefault()
	$('.grid').toggleClass('condensed')
	$(this).toggleClass('condensed')
})

var closePhoto = function() {
	$('body').removeClass('photo-open')
	$('.photo-large').remove()
}

var closePhotoIfOpen = function(evt) {
	if($('body').hasClass('photo-open')) {
		if(evt.keyCode == 27) {
			if(history) {
				history.back()
			}
		}
	}
}

var getImage = function(image) {
	image = $(image)
	var src = image.attr('data-enlarged')
	if(!src) {
		src = image.attr('src')
	}
	return src
}


var onImageClick = function(evt) {
	if($('.photo-large').length > 0) {
		return;
	}
	startingTarget = evt.target
	var src = getImage(this)
	var s = '<div class="photo-large"><div class="left">&nbsp</div>'+ 
		'<img class="center" src="' + src + '" /><div class="right" ></div>' + 
		'<div class="times">&times;</div></div>'
	$('body').append(s);
	setTimeout(function() {
		$('body').addClass('photo-open')
		if(history.pushState) {
			history.pushState({}, document.title, window.location.pathname + "#show-pictures")
		}
		else {
			window.location.hash = 'show-pictures'
		}
	})
}

$('body').on('click', '.photo-large .times', function(evt) {
	history.back()
})

$('body').on('click', '.photo-large .right', function(evt) {
	var images = []
	$($(startingTarget).attr('data-lightbox-selector')).each(function() {
		images.push(getImage(this))
	})
	
	var current = $('.photo-large .center').attr('src')
	var i = _.indexOf(images, current)
	while(i-- > -1) {
		var v = images.shift()
		images.push(v)
	}
	$('.photo-large .center').attr('src', images[0])
})

$('body').on('click', '.photo-large .left', function(evt) {
	var images = []
	$($(startingTarget).attr('data-lightbox-selector')).each(function() {
		images.push(getImage(this))
	})
	
	var current = $('.photo-large .center').attr('src')
	var i = _.indexOf(images, current)
	
	while(i++ <= images.length) {
		var v = images.pop()
		images.unshift(v)
	}
	$('.photo-large .center').attr('src', images[0])
})

$('body').on('keydown', function(evt) {
	closePhotoIfOpen(evt)
})

if(window.history) {
	window.onpopstate = function(evt) {
		closePhoto()
	}
}

var setupLightbox = function(selector) {
	$(selector).click(onImageClick).attr('data-lightbox-selector', selector)
}

module.exports = setupLightbox